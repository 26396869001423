import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Conversations from "./Conversations";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Online from "../../images/platform/online.jpg"
import Offline from "../../images/platform/offline.jpg"


function TypePlatformOperationCard() {
    const { t } = useTranslation();
    let typeOperation = [
        {
            titleCenter: `${t('online')}`,
            imageUrl: `${Online}`,
        },
        {
            titleCenter: `${t('offline')}`,
            imageUrl: `${Offline}`
        }
    ]
    return(
        <Box className="py-4">
            <Container maxWidth="xl">
                <Box className="py-14">
                    <h2 className="font-bold text-2xl">{t('supported.modes.operation')}: </h2>
                </Box>
                <Box className="grid gap-24 lg:grid-cols-2 sm:grid-cols-1">

                    {typeOperation.map((item, index) => (
                        <Conversations key={index} titleCenter={item?.titleCenter} imageUrl={item?.imageUrl}/>

                    ))}
                </Box>
            </Container>
        </Box>
    )
}
export default TypePlatformOperationCard;
