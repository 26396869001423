import React from 'react';
import './App.css';
import HeaderPage from "./components/HeaderPage";

function App() {

    return (
        <div className="App" >
           <HeaderPage  />
        </div>
  );
}

export default App;
