import * as React from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Conversations from "./Conversations";
import { useTranslation } from "react-i18next";
import CardOne from "../../images/body-cards/card-one.jpg"
import CardTwo from '../../images/body-cards/card-two.jpg'
import CardThree from '../../images/body-cards/cardThree.jpg'
import CardFour from '../../images/body-cards/card-four.jpg'
import CardFive from '../../images/body-cards/card-five.jpg'
function ContentCards(){
    const {t} = useTranslation()
    let restaurantsJson = [
        {
            title: t('cafe'),
            imageUrl: `${CardOne}`,
            content: t('quick.learning.use')
        },
        {
            title: t('restaurant'),
            imageUrl: `${CardTwo}`,
            content: t('start.without.unnecessary.servers')
        },
        {
            title: t('fast.food'),
            imageUrl: 'https://www.carrots-india.com/wp-content/uploads/2015/08/4.jpg',
            content: t('instant.dispatch.orders.kitchen.table')
        },
        {
            title:  t('food.track'),
            imageUrl: `${CardThree}`,
            content: t('hing.increase.number.customers.served.certain.period.time')
        },
        {
            title: t('family.business.works.delivery'),
            imageUrl: `${CardFour}`,
            content: t('increase.financial.performance')
        },
        {
            title: t('family.business.works.delivery'),
            imageUrl: `${CardFive}`,
            content: t('need.android.device')
        }
    ];

    return (
    <Box>
        <Container maxWidth="xl">
        <Box className="py-14">
                <h2 className="font-bold text-2xl">{t("where.platform.used")}?</h2>
        </Box>
        <Box className="grid xl:grid-cols-3 gap-4 lg:grid-cols-2 sm:grid-cols-1">

            {restaurantsJson.map((item, index) => (
                <Conversations key={index} title={item?.title} imageUrl={item?.imageUrl} content={item?.content}/>

            ))}
        </Box>
        </Container>
    </Box>
)
}
export default ContentCards;
