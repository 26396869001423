import Box from "@mui/material/Box";
import SliderCaruselComponent from "./slider/SliderCaruselComponent";
import Container from "@mui/material/Container";
import * as React from "react";
import { useTranslation } from "react-i18next";


function BodyFooter(){
    const {t} = useTranslation();
    return(
        <Box>
            <Container maxWidth="xl">
            <Box className="py-14">

                    <h2 className="font-bold text-2xl">{t("new.platform.is")}:</h2>

            </Box>
           <Container maxWidth="sm">
               <SliderCaruselComponent/>
           </Container>
            </Container>
        </Box>
    )
}
export default BodyFooter;
