import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import { useTranslation } from "react-i18next";

interface DataObject {
    name: string;
    Egarson: number;
    "An'anaviy POST-tizim": number;
    amt: number;
    "traditional.post.system": string;
}

const data: DataObject[] = [
    { name: "low.requirements.start", "Egarson": 5, "An'anaviy POST-tizim": 2, amt: 5, "traditional.post.system": "2" },
    { name: "quick.easy.platform.expansion", "Egarson": 5, "An'anaviy POST-tizim": 3, amt: 5, "traditional.post.system": "3" },
    { name: "mobility.service.personnel", "Egarson": 5, "An'anaviy POST-tizim": 3, amt: 5, "traditional.post.system": "3" },
    { name: "low.cost.automation", "Egarson": 5, "An'anaviy POST-tizim": 2, amt: 5, "traditional.post.system": "2" }
];

export default function SimpleBarChart(){
    const {t} = useTranslation();
    const translatedData = data.map((item) => ({
        ...item,
        name: t(item.name),
        ["An'anaviy POST-tizim"]: t(item["traditional.post.system"]) ?? ""
    }));
    const traditionalPostSystem = t("traditional.post.system");
    return (
    <ResponsiveContainer width="100%" height={400}>
        <BarChart
            data={translatedData}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
            }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={traditionalPostSystem} fill="#8884d8" />
        <Bar dataKey="Egarson" fill="#82ca9d" />
    </BarChart>
    </ResponsiveContainer>
)
}
