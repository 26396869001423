import React from "react";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import SlideCard from "./slideCard/SlideCard";
import { useTranslation } from "react-i18next";
import SliderOne from "../../images/slider/slider-one.jpg"
import SliderTwo from '../../images/slider/slider-two.jpg'
import SliderThree from '../../images/slider/slider-three.jpg'
import SliderFour from '../../images/slider/slider-four.jpeg'
import SliderFive from '../../images/slider/slider-five.jpg'
import SliderSix from '../../images/slider/slider-six.jpg'
function CustomNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
            <FaChevronRight />
        </div>
    );
}

function CustomPrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
            <FaChevronLeft />
        </div>
    );
}

function SliderCaruselComponent(){
    const {t} = useTranslation();
    let arrJson = [
        {
            title: t('simplicity'),
            imageUrl: `${SliderOne}`,
            content: t('quick.learning.use')
        },
        {
            title: t('ease'),
            imageUrl: `${SliderTwo}`,
            content: t('start.without.unnecessary.servers')
        },
        {
            title: t('rapidity'),
            imageUrl: `${SliderThree}`,
            content: t('instant.dispatch.orders.kitchen.table')
        },
        {
            title: t('most.important'),
            imageUrl: `${SliderFour}`,
            content: t('hing.increase.number.customers.served.certain.period.time')
        },
        {
            title: t('most.profitable'),
            imageUrl: `${SliderFive}`,
            content: t('increase.financial.performance')
        },
        {
            title: t('most.importantly'),
            imageUrl: `${SliderSix}`,
            content: t('need.android.device')
        }
    ];

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1200, // for screen sizes >= 1200px
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 992, // for screen sizes >= 992px
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768, // for screen sizes >= 768px
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576, // for screen sizes >= 576px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Box className="p-6 h-auto text-center ">
            <Container maxWidth="xl">
                <Slider {...settings}>
                    {arrJson.map((item, index) => (
                        <SlideCard key={index} title={item.title} imageUrl={item.imageUrl} content={item.content} />
                    ))}
                </Slider>
            </Container>
        </Box>
    );

}


export default SliderCaruselComponent;
