import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import React from 'react';


function TranslationLang() {
    const {i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const [lang, setLang] = useState("O'zbekcha");

    const handleLanguageChange = (language: any) => {
        i18n.changeLanguage(language);
        if (language === 'uz'){
            setLang("O'zbekcha");
        }
        else if (language === 'ru'){
            setLang("Русский");
        }
        else if (language === 'en'){
            setLang("English");
        }
        handleMenuClose();
    };

    return (
        <div>
            <Button
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                className="rounded-md shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
                style={{ backgroundColor: '#0066ff', color: 'white' }}
            >
                {lang}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleLanguageChange('uz')}>
                    <img src="https://cdn.countryflags.com/thumbs/uzbekistan/flag-round-250.png" alt="Russia flag" className="inline-block h-4 w-4 mr-2" />
                    O'zbekcha
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('ru')}>
                    <img src="https://cdn.countryflags.com/thumbs/russia/flag-round-250.png" alt="Russia flag" className="inline-block h-4 w-4 mr-2" />
                    Русский
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('en')}>
                    <img src="https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png" alt="USA flag" className="inline-block h-4 w-4 mr-2" />
                    English
                </MenuItem>
            </Menu>
        </div>
    );

}

export default TranslationLang;
