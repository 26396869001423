import * as React from "react";
import { useTranslation } from "react-i18next";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}


const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function Authorization(props: any) {
    const {t} = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Container maxWidth="lg">
            <Card sx={{ width: "100%" }}>
                <CardMedia
                    component="img"
                    height="150"
                    image={props?.imageUrl}
                    alt="Paella dish"
                    sx={{ height: "250px", objectFit: "cover" }}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {props?.content}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>Bosqichlar:</Typography>
                        <Typography variant="body2" color="text.secondary">
                            <div className="flex flex-row grid gap-4">
                                <div>{props?.stepOne}</div>
                                <div>{props?.stepTwo}</div>
                                <div>{props?.stepThree}</div>
                                <div>{props?.stepFour}</div>
                                <div>{props?.stepFive}</div>
                            </div>
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Container>

    );
}
