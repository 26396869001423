import * as React from 'react';
import HeaderBody from "./HeaderBody";
import BodyCard from "./card/BodyCard";
import BodyFooter from "./BodyFooter";
import Box from "@mui/material/Box";
import ContentCards from "./card/ContentCards";
import TypePlatformOperationCard from "./card/TypePlatformOperationCard";
import ChartStatistics from "./chart/ChartStatistics";
import PlatformManagement from "./platformManagment/PlatformManagement";
import AuthorizationComponent from "./autorization/AuthorizationComponent";
import FooterHeaderPage from "./FooterHeaderPage";


function HeaderPage() {

    return (
        <Box>
      <Box sx={{ backgroundColor: "#f1f5f9", paddingY: "2rem" }}>
          <HeaderBody/>
          <BodyCard/>
          <BodyFooter/>
          <ContentCards/>
          <TypePlatformOperationCard/>
          <ChartStatistics/>
          <PlatformManagement/>
         <AuthorizationComponent/>
      </Box>
            <FooterHeaderPage/>
        </Box>
    )
}

export default HeaderPage;
