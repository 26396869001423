import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import BreakfastDiningOutlinedIcon from "@mui/icons-material/BreakfastDiningOutlined";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import TranslationLang from "./TranslationLang";
import { useTranslation } from "react-i18next";
import headerBg from '../images/header/header-bg.jpg'
function HeaderBody() {
    const {t} = useTranslation();
    return (
        <Box sx={{flexGrow: 1}}>
            {/*<div>{t("welcome")} </div>*/}
            <AppBar position="fixed" sx={{backgroundColor: "#ff751a", maxWidth:"100%", minWidth:"390px"}}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}} className="flex items-center">
                        <Box sx={{"& svg": {width: "2rem", height: "2rem"}}}>
                            <BreakfastDiningOutlinedIcon/>
                        </Box>
                        <Typography variant="h6" className="pl-2 sm:pl-0 sm:text-lg">
                            E-Garson
                        </Typography>
                    </Box>

                  <Box sx={{display: 'flex', alignItems: 'center'}} className="flex items-center">
                      <TranslationLang/>
                      <Button classes="text-white sm:p-0"
                              sx={{color: "white", padding: "4px", border: "1px solid white", margin: "1rem"}}><a
                          href="http://cabinet.egarson.uz">{t("personal.cabinet")}</a></Button>
                  </Box>
                </Toolbar>
            </AppBar>
            <Box className="bg-no-repeat bg-center w-full h-full relative"
                 style={{
                     height: "60vh",
                     backgroundImage: `url(${headerBg})`,
                     backgroundSize: "cover"
                 }}>
                <div className="bg-black opacity-60 w-full h-full">
                </div>
                <div className="absolute top-1/3  left-1/3 right-1/3">
                    <h2 className="flex 2xl:6xl xl:text-4xl lg:text-2xl md:text-xl sm:text-lg font-normal text-center text-white mx-auto">
                        eGarson - {t("platform.optimizing.workflows.catering")}
                    </h2>
                </div>
            </Box>
        </Box>
    )
}
export default HeaderBody;
