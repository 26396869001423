import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import CabinetImage from "../../../images/footer/personal-cabinet.jpg"

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ManagementCard() {
    const {t} = useTranslation()
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
       <Container className="text-center">
           <Card sx={{ maxWidth: "1/2" }}>
               <CardHeader
                   title={t("personal.cabinet.allows.manage.following.elements")}/>
               <CardMedia
                   component="img"
                   height="194"
                   image={CabinetImage}
                   alt="Paella dish"
               />
               <CardContent>
                   <Typography variant="body2" color="text.secondary">
                       {t('enter.personal.account.two.factor.authorization.used.increase.security')}.
                   </Typography>
               </CardContent>
               <CardActions disableSpacing>
                   <ExpandMore
                       expand={expanded}
                       onClick={handleExpandClick}
                       aria-expanded={expanded}
                       aria-label="show more"
                   >
                       <ExpandMoreIcon />
                   </ExpandMore>
               </CardActions>
               <Collapse in={expanded} timeout="auto" unmountOnExit>
                   <CardContent>
                       <Typography paragraph>Turlar:</Typography>
                       <Typography paragraph>
                           <div className="flex flex-row grid gap-4">
                               <div> 1.Muassasa</div>
                               <div> 2.Xodimlar</div>
                               <div> 3.Menyu</div>
                               <div> 4.Ombor</div>
                               <div> 5.Hisobotlarni ko'rish.</div>
                           </div>
                       </Typography>
                   </CardContent>
               </Collapse>
           </Card>
       </Container>

    );
}
