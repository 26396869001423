import * as React from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import SimpleBarChart from "./SimpleBarChart";
import { useTranslation } from "react-i18next";



function ChartStatistics(){
    const {t} = useTranslation();
    return(
       <Box className="py-24">
           <Container maxWidth="lg">
               <h2 className="font-bold text-2xl py-2">{t('whey.egarson')}?</h2>
          <Box className="py-8">
              <SimpleBarChart/>
          </Box>
           </Container>
       </Box>
    )
}
export default ChartStatistics;
