import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Authorization from "./Authorization";
import { useTranslation } from "react-i18next";
import CardOne from "../../images/footer-card/card-one.webp"
import CardTwo from "../../images/footer-card/card-two.jpg"
import CardThree from "../../images/footer-card/card-three.jpg"
export default function AuthorizationComponent() {
    const {t} = useTranslation();
    let restaurantsJson = [
        {
            imageUrl: `${CardOne}`,
            content: t('Har qanday android qurilmaning, xususan, mobil qurilma yoki planshetning mavjudligi'),
            stepOne: `1. ${t('need.client.application.waiters.device.based.android')}`,
            stepTwo: `2. ${t('need.client.application.waiters.device.based.android')}`,
            stepThree: `3.${t('using.waiter.client.need.device.with.least.ram')}`,
            stepFour: "",
            stepFive: ""
        },
        {
            imageUrl: `${CardTwo}`,
            content: ` ${t('printer.printing.invoices.customers.orders.kitchen')}`,
            stepOne: `1. ${t('printers.wide.range.connection.interfaces.supported')}`,
            stepTwo: '- Bluetooth;\n - Wi-Fi;\n - RJ45.',
            stepTwoThree: `3. ${t('least.thermal.invoice.order.don\'t.need.printer')}`,
            stepFour: "",
            stepFive: ""
        },
        {
            imageUrl:`${CardThree}`,
            content: `${t('network.permanent.internet.connection.not.required')}`,
            stepOne: `1 ${t('telegram.bot.during.data.statistics')}`,
            stepTwo: `2. ${t('also.need.update.menu.list.service.personnel')}`,
            stepThree: `3 ${t('waiter.client.android.bluetooth.thermal.printer')}`,
            stepFour: `4 ${t('network.needed.waiters.orders')}`,
        }
    ]
    return (
        <Box>
            <Box className="py-14">
                <Container maxWidth="lg">
                    <div>
                        <div className="pb-6">
                            <h2 className="font-bold text-2xl ">{t('minimum.requirements.eGarson.platform')}.</h2>
                        </div>
                        <div className="grid xl:grid-cols-3 lg:grid-cols-2  sm:grid-cols-1 gap-y-4">
                            {restaurantsJson.map((item, index) => (
                                <Authorization key={index} imageUrl={item?.imageUrl} content={item?.content}
                                               stepOne={item.stepOne} stepTwo={item.stepTwo} stepThree={item.stepThree}
                                               stepFour={item.stepFour} stepFive={item.stepFive}/>

                            ))}
                        </div>
                    </div>

                </Container>
            </Box>
        </Box>

    )
}
