import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import uz from './json/uz.json'
import ru from './json/ru.json'
import en from './json/en.json'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    uz: {
        translation: uz
    },
    ru: {
        translation: ru
    },
    en: {
        translation: en
    }
};

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        lng: "uz",
        detection: {
            order: ['localStorage', 'cookie'],
            cashes: ['localStorage', 'cookie'],
        },


        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
