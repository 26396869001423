import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as React from "react";
import ManagementCard from "./card/ManagementCard";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Deliver from "../../images/footer/deliver.jpg"
export default function PlatformManagement(){
    const {t} = useTranslation();
    return (
        <Box>
            <Box className="py-14">
                <Container maxWidth="lg" >
                    <div>
                        <div className="pb-6">
                            <h2 className="font-bold text-2xl ">{t("platform.management")}</h2>
                            <p>- {t("only.for.online.mode")}</p>
                        </div>
                            <ManagementCard/>
                   </div>
                     <div>
                         <div className="py-8">
                             <h2 className="font-bold text-2xl">{t("want.organize.delivery.increase.profits.without.overpaying")}?</h2>
                         </div>
                             <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden h-full">
                                 <div className="relative h-full overflow-hidden bg-cover sm:h-96" style={{
                                     backgroundImage: `url(${Deliver})`,
                                     backgroundPosition: "center",
                                     backgroundRepeat: "no-repeat",}}>
                <span
                    className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-70"></span>
                                     <div className="relative z-10 flex flex-col flex-auto h-full p-4">
                                         <h5 className="pt-2 mb-6 font-bold text-white">
                                             <CardHeader
                                                 title={t("personal.cabinet.allows.manage.following.elements")}/>
                                         </h5>
                                         <Typography className="text-white"> {t('online.deliver')}.</Typography>
                                     </div>
                                 </div>
                             </Paper>
                     </div>
                </Container>
            </Box>
        </Box>
    )
}
