import * as React from "react";

 function FooterHeaderPage() {
    return (
        <footer className="bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://flowbite.com/"
                                                                                          className="hover:underline">Flowbite™</a>. E-Garson
    </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="https://t.me/djam388" target={"_blank"} className="hover:underline hover:border-blue-400 hover:text-blue-400">Telegram</a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
export default FooterHeaderPage;
