import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



export  interface  SlideCardProps {
    title?: string;
    imageUrl?: string;
    content?: string;
    titleCenter?: string
}

function SlideCard(props: SlideCardProps) {
    return (

        <Card sx={{ maxWidth: 500 }}>
            <CardHeader
                title={props?.title}
            />
            <CardMedia width="500px"  component="img" height="194" image={props?.imageUrl} alt="Paella dish"  className="overflow-hidden"/>
            <CardContent>
                <Typography>{props?.content}</Typography>
            </CardContent>
        </Card>
    );
}
export default SlideCard;
