import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import bodyCardBg from '../../images/body/body.jpg'

function BodyCard() {
    const {t} = useTranslation();
    return (
        <Box className="w-full h-full py-6">
            <Container maxWidth="xl">

                <h2 className="font-weight text-2xl py-4">{t("solution.solves.problems")}</h2>
            </Container>
            <div className="sm:w-full">
                <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden h-full">
                    <div className="relative h-full overflow-hidden bg-cover h-full  xl:h-full lg:h-96 md:h-48 sm:h-44 xl:py-32 lg:py-24 md:py-16 sm:py-12" style={{
                        backgroundImage: `url(${bodyCardBg})`,
                        backgroundPosition: "center"
                    }}>

                        <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-70"></span>
                        <div className="relative  z-10 flex flex-col flex-auto h-full p-4 sm:text-sm text-white lg:text-xl lg:top-0">
                            <Typography>1.{t("minimize.time.takes.receive.orders.customers")}</Typography>
                            <Typography >2.{t("solve.problem.lack.service.personnel.during.peak.hours")}</Typography>
                            <Typography >3.{t("increase.potential.customer.through.prompt.service")}</Typography>
                        </div>
                    </div>
                </Paper>
            </div>
        </Box>

    )
}

export default BodyCard
