import React from "react";
import Paper from '@mui/material/Paper';
import { SlideCardProps } from "../slider/slideCard/SlideCard";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";

function Conversations(props: SlideCardProps) {
    return (
        <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden h-full">
            <div className="relative h-full overflow-hidden bg-cover sm:h-96" style={{
                backgroundImage: `url(${props?.imageUrl})`,
                backgroundPosition: "center"
            }}>
                <span
                    className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-70"></span>
                <div className="relative z-10 flex flex-col flex-auto h-full p-4">
                    <h5 className="pt-2 mb-6 font-bold text-white">
                        <CardHeader
                            title={props?.title}/>
                    </h5>
                    <Typography className="text-white">{props?.content}</Typography>
                    <h2 className="text-white text-5xl">{props?.titleCenter}</h2>
                </div>
            </div>
        </Paper>

    )
}

export default Conversations;
